<template>
  <div id="cancel">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1 h-inner" style="height: 150px">
      <div class="overlay bg-black op-9"></div>
    </div>
    <section class="about-wrap style2 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 text-center">
            <div class="about-content mt-5">
              <div class="content-title style2 mt-5">
                <h2 class="mt-5">Order Cancelled</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <div class="cancelbg text-center">
              <img :src="cancel" alt="Image" />
            </div>
          </div>
          <div class="col-md-12 mt-30 text-center">
            <router-link to="/menu" class="btn style1" target="_blank"
              >Go to Menu Page <i class="flaticon-right-arrow-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
export default {
  name: "cancel",
  data() {
    return {
      cancel: require("@/assets/img/cancel.png"),
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    localStorage.removeItem("submitOrder");
  },
};
</script>
<style scoped>
.breadcrumb-wrap {
  position: relative;
  height: 100%;
  padding: 71px 0 100px;
  overflow: hidden;
}
</style>
